<template>
  <div style="background: #F7F9FC;color: #000;height: 100%;">
    <van-nav-bar title="门店评价" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div class="hed">
      <div class="let">
        <div class="pinfen left">{{grade?grade.toFixed(1):''}}</div>
        <div class="pinfen_b left">
          <div class="zongpin">总评分</div>
          <van-rate :size="14" allow-half class="xinc" color="#ffd21e" v-model="grade" readonly />
        </div>
      </div>
      <div class="rit">
        <div class="shul">评论数</div>
        <div>{{total}}条</div>
      </div>
    </div>
    <div style="margin-top:121px;height: 100%;background: #fff">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoads"
        :offset="10"
      >
        <div class="pin_list2" v-for="(item,index) in list" :key="index">
          <div class="pin_a">
            <img class="c_touxian" v-if="item.avatar" :src="item.avatar" alt />
            <img class="c_touxian" v-else src="../../assets/img/touxiang.png" alt />
          </div>
          <div class="left pin_b">
            <div class="yonghu_name">
              <span class="left">{{item.name}}</span>
              <van-rate
                :size="15"
                allow-half
                class="xinc right"
                color="#ffd21e"
                v-model="item.grade"
                readonly
              />
            </div>
            <div class="xinji2">
              <!-- <span class="fen2" style="color:#ffd21e">{{item.grade?item.grade.toFixed(1):''}}分</span> -->
              <span class style="color: #999;font-size: 12px;">{{item.createdDate}}</span>
            </div>

            <div class="pinlun">{{item.content}}</div>
          </div>
        </div>
      </van-list>
      <div style="height: 45px;"></div>
      <div class="c_tianjia">添加评论</div>
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
  </div>
</template>

<script>
import shopModel from "../../api/shop";
import { ImagePreview } from "vant";
export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      list: [],
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      gid: this.$route.query.gid,
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false,
      grade: this.$route.query.grade
    };
  },
  methods: {
    onLoads() {
      this.page++;
      this.onLoad();
    },
    onLoad() {
      const data = {
        gid: this.gid,
        type: this.active,
        page: this.page,
        size: this.size
      };
      shopModel
        .pinlunlist(data)
        .then(e => {
          let rows = e.data;
          this.loading = false;
          this.total = e.pageInfo.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
          console.log(e);
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.hed {
  background: #fff;
  width: 100%;
  overflow: hidden;
  height: 75px;
  padding: 10px 25px;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 46px;
  border-bottom: 12px solid #f5f5f5;
}

.let {
  float: left;
  overflow: hidden;
  width: 70%;
  border-right: 2px solid #dcdcdc;
}
.rit {
  float: left;
  font-size: 14px;
  margin-left: 10px;
}
.shul {
  color: #666;
  font-size: 12px;
  line-height: 14px;
  margin-top: 3px;
}
.list {
  padding: 20px 25px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.list_img {
  float: left;
  width: 47%;
}
.c_img {
  width: 100%;
  height: 120px;
  //   width: 45%;
}
.pinfen {
  font-size: 36px;
  line-height: 38px;
}
.pinfen_b {
  font-size: 14px;
  margin-top: 2px;
  margin-left: 8px;
}
.xinc {
  font-size: 16px;
}
.zongpin {
  line-height: 16px;
  font-size: 12px;
  color: #666;
}
.pin_list2 {
  // margin: 15px 0;
  overflow: hidden;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 20px;
}
.pin_b {
  width: 89%;
}
.pin_a {
  float: left;
  width: 10%;
  height: 30px;
}
.c_touxian {
  float: left;
  width: 30px;
  border-radius: 15px;
}
.yonghu_name {
  overflow: hidden;
  line-height: 18px;
  height: 18px;
  font-size: 14px;
}
.xinc {
  font-size: 16px;
  margin-top: 2px;
}

.xinji2 {
  overflow: hidden;
  line-height: 14px;
  font-size: 18px;
}
.c_tianjia {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #000;
  font-size: 14px;
  text-align: center;
  background: #ffd21e;
}
.list_img:nth-child(2n) {
  float: right;
}
</style>